<template>
  <div class="admin-view">
    <section class="right-view" id="map"></section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: null,
      drivers: [],
    };
  },
  async mounted() {
    // firebase.auth().onAuthStateChanged((user) => {
    //   this.user = user;
    // });
    // firebase
    //   .firestore()
    //   .collection("users")
    //   .where("active", "==", true)
    //   .onSnapshot((snap) => {
    //     this.drivers = [];
    //     for (let i = 0; i < snap.docs.length; i++) {
    //       var driver = snap.docs[i].data();
    //       this.drivers.push(driver);
    //     }
    //   });
    this.signOutButtonPressed();
  },
  methods: {
    signOutButtonPressed() {
      // eslint-disable-next-line no-undef
      new google.maps.Map(document.getElementById("map"), {
        zoom: 10,
        // eslint-disable-next-line no-undef
        center: new google.maps.LatLng(37.33446146, -122.04380955),
        // eslint-disable-next-line no-undef
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });
    },
  },
};
</script>
<style>
.admin-view {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.left-view {
  width: 250px;
  padding: 12px;
}
.right-view {
  flex-grow: 1;
}
</style>
